"use client";

import SomethingWentWrong from "@/components/SomethingWentWrong";

export default function Error({
  error,
  reset,
}: {
  error: (Error & { digest?: string }) | null;
  reset?: () => void;
}) {
  return <SomethingWentWrong error={error} reset={reset} />;
}
