"use client";

import { Alert } from "antd";

export default function SomethingWentWrong({
  error,
  errorMessage,
  reset,
  shouldAllowRetry = true,
}: {
  error: (Error & { digest?: string }) | null;
  errorMessage?: string;
  reset?: () => void;
  shouldAllowRetry?: boolean;
}) {
  return (
    <>
      <Alert
        type="error"
        message={
          <div className="items-center">
            <p>{errorMessage ?? "Sorry, something went wrong. Please try again later."}</p>
            <p>
              If this problem persists, contact us at <a href="mailto:support@condoit.io">support@condoit.io</a>
            </p>
          </div>
        }
      />
      {shouldAllowRetry && reset && (
        <button
          className="inline-flex justify-center rounded-lg bg-red-600 my-2 px-3 py-0 text-sm font-semibold outline-2 outline-offset-2 transition-colors"
          onClick={() => {
            reset?.();
          }}
        >
          <p className="text-white">Retry</p>
        </button>
      )}
    </>
  );
}
